import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';

import { ModalConsumer as Consumer } from '@core/context';
import { usePrevious } from '@core/hook';
import { EColor, EZIndexName, WithThemeProps } from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { fadeInAnimation, fadeOutAnimation } from '../animation';
import { Box } from '../layout';
import { BaseModalBackgroundProps, ModalBaseBackgroundProps } from './interface-modal';

const TIMEOUT_CLOSING_MODAL = 300;

const BaseModalBackground = ({ children, isFull, ...props }: BaseModalBackgroundProps) => (
  <Box
    position="fixed"
    top={0}
    left={0}
    right={0}
    bottom={0}
    width="100vw"
    zIndex={EZIndexName.MODAL}
    overflow="auto"
    padding={isFull ? '0' : '0 20px'}
    data-testid="base-modal-background"
    {...props}
  >
    {children}
  </Box>
);

const ModalBackground = styled(BaseModalBackground)(({
  isOpen,
  theme: { palette },
}: { isOpen: boolean } & WithThemeProps) => {
  const { getBackgroundColor } = getPaletteHandlers(palette);

  return css`
    background-color: ${getBackgroundColor({ commonType: EColor.BLUE, intensity: EColor.R300 })};
    animation: ${isOpen ? fadeInAnimation : fadeOutAnimation} 0.3s forwards;
  `;
});

export const ModalBase = ({
  isOpen,
  onCloseModal,
  hasCloseButton = true,
  children,
  size,
  isFull,
  maxWidth,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  styledContentWrapper: StyledContentWrapper,
}: ModalBaseBackgroundProps) => {
  const node = useRef<HTMLDivElement>(null);
  const prevBodyOverflowStyle = useRef(null);

  const [isClosing, setIsClosing] = useState(false);
  const didPreviouslyOpen = usePrevious<boolean>(isOpen);

  useEffect(() => {
    if (!isOpen && didPreviouslyOpen) {
      setIsClosing(true);
      setTimeout(() => {
        setIsClosing(false);
      }, TIMEOUT_CLOSING_MODAL);
    } else {
      setIsClosing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // Handle Escape keydown
  useEffect(() => {
    function handleKeydown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        onCloseModal();
      }
    }

    if (isOpen && hasCloseButton) {
      document.addEventListener('keydown', handleKeydown, { once: true });
    }

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, onCloseModal]);

  // Handle changing document.body styles based on isOpen state
  useEffect(() => {
    if (isOpen) {
      prevBodyOverflowStyle.current = document.body.style.overflow;
      document.body.style.overflow = 'hidden';
    }

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      document.body.style.overflow = prevBodyOverflowStyle.current || '';
    };
  }, [isOpen]);

  const handleBackgroundClick: MouseEventHandler<HTMLDivElement> &
    MouseEventHandler<HTMLButtonElement> = (e) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access

    if (node?.current?.contains && !node.current.contains(e.target as Node)) {
      onCloseModal();
    }
  };

  return (
    <Consumer>
      {(modalNode) => {
        if (modalNode && (isOpen || didPreviouslyOpen || isClosing)) {
          return createPortal(
            <ModalBackground
              isOpen={isOpen}
              isFull={isFull}
              onClick={hasCloseButton ? handleBackgroundClick : null}
            >
              <StyledContentWrapper
                size={size}
                maxWidth={maxWidth}
                isClosing={isClosing}
                ref={node}
                onCloseModal={onCloseModal}
                isFull={isFull}
              >
                {children}
              </StyledContentWrapper>
            </ModalBackground>,
            modalNode,
          );
        } else {
          return null;
        }
      }}
    </Consumer>
  );
};
