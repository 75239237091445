import type { AxiosInstance } from 'axios';
import { ParsedUrlQuery } from 'querystring';

import {
  ACCOUNT_TRANSACTION_PER_PAGE,
  ARTICLES_TO_SHOW,
  FAQ_SEARCH_PER_PAGE,
  GENERAL_CONDITIONS_SLUGS,
  HISTORY_CLAIM_PER_PAGE,
  HISTORY_GIFT_PER_PAGE,
  HISTORY_ORDER_PER_PAGE,
  INVOICES_PER_PAGE_STEP,
  PRIVACY_POLICY_SLUGS,
  SEARCH_PRODUCT_PER_PAGE,
} from '@core/constant';
import {
  ECurrency,
  ELanguageTags,
  ELiveChartsPeriod,
  EMetal,
  ERouting,
  EUnit,
  Price,
  WeightUnitType,
} from '@core/type';
import {
  CollectionsBannersRequest,
  EFAQCategory,
  EQueryKey,
  ForecastedDeliveryRequestEntity,
  InvestmentChartsRequest,
  LiveChartsRequest,
  PageRatingRequest,
  SpotPriceCaratsRequest,
  SpotPriceCurrenciesRequest,
  SpotPriceHistoryRequest,
  SpotPriceWeightsRequest,
  SpotPriceWeightUnitType,
} from '@core/type/api';
import { ESanityField, ESanityWidget } from '@core/type/sanity';

import {
  AccountTransactionsService,
  BannerService,
  BlogCategoriesService,
  ClaimsService,
  CollectionsService,
  ContentService,
  CountriesService,
  CurrencyRatesService,
  CustomerSurveysService,
  DeliveryCountriesService,
  DiscountService,
  FAQService,
  GiftService,
  GiftsService,
  HighlightedArticleService,
  HistoryService,
  HomepageArticlesService,
  HomepageProductsService,
  KycService,
  LiveChartsService,
  MarketAlertService,
  MostPurchasedProductsService,
  OnfidoSDKService,
  OrderService,
  PageService,
  PriceService,
  ProductAlertService,
  ProductService,
  SearchService,
  StaticPageService,
  StockAlertService,
  TeamMembersService,
  TrustpilotService,
  TutorialVideosService,
  UserService,
  WalletService,
  WidgetService,
  WishlistService,
} from '../service';
import { ForecastedDeliveryService } from '../service/forecasted-delivery';

export function getParamsQueriesKeys<RequestDto, ResponseDto>({
  queryKey,
  language: lng,
  axiosInstance,
  currency,
  params,
  isLogged,
}: {
  queryKey: EQueryKey;
  axiosInstance: AxiosInstance;
  language?: ELanguageTags;
  currency?: ECurrency;
  params?: RequestDto;
  isLogged?: boolean;
}): {
  queries: string[];
  shouldLogged?: boolean;
  queryFn: () => Promise<ResponseDto>;
} {
  const language: Exclude<ELanguageTags, ELanguageTags.DEFAULT> =
    lng === ELanguageTags.DEFAULT ? ELanguageTags.EN_US : lng;

  switch (queryKey) {
    case EQueryKey.CART:
      return {
        queries: [queryKey, language, currency],
        queryFn: () => OrderService.getCart(axiosInstance, language) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.BLOG_ARTICLE:
      return {
        queries: [queryKey, language, (params as { slug: string })?.slug],
        queryFn: () =>
          PageService.getBlogArticlePage(
            axiosInstance,
            (params as { slug: string })?.slug,
            language,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.FAQ_ARTICLE:
      return {
        queries: [queryKey, language, (params as { slug: string })?.slug],
        queryFn: () =>
          FAQService.getFAQArticle(
            axiosInstance,
            language,
            (params as { slug: string })?.slug,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.AUTO_SAVINGS_PRODUCTS:
      return {
        queries: [queryKey, language, currency],
        queryFn: () =>
          ProductService.getAutoSavingsProducts(axiosInstance, language) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.BANK_ACCOUNTS:
      return {
        queries: [queryKey, language],
        queryFn: () => UserService.getBankAccounts(axiosInstance, language) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.BANNER:
      return {
        queries: [queryKey, language],
        queryFn: () => BannerService.getBanner(axiosInstance, language) as Promise<ResponseDto>,
      };
    case EQueryKey.BLOG_CATEGORY:
      return {
        queries: [
          queryKey,
          language,
          (params as { slug: string })?.slug,
          (params as { page: string })?.page || '1',
        ],
        queryFn: () =>
          PageService.getBlogCategory(
            axiosInstance,
            (params as { slug?: string })?.slug,
            Number((params as { page: string })?.page || '1'),
            ARTICLES_TO_SHOW,
            language,
          ) as Promise<ResponseDto>,
      };

    case EQueryKey.BLOG_CATEGORIES:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          BlogCategoriesService.getBlogCategories(axiosInstance, language) as Promise<ResponseDto>,
      };
    case EQueryKey.BLOG_HIGHLIGHTED_ARTICLE:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          HighlightedArticleService.getHighlightedArticle(
            axiosInstance,
            language,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.BLOG_ARTICLES:
      return {
        queries: [queryKey, language, (params as { page: string })?.page || '1'],
        queryFn: () =>
          ContentService.blog(
            axiosInstance,
            language,
            Number((params as { page: string })?.page || '1'),
            ARTICLES_TO_SHOW,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.BLOG_SEARCH:
      return {
        queries: [
          queryKey,
          language,
          (params as { q: string })?.q,
          (params as { page: string })?.page || '1',
        ],
        queryFn: () =>
          PageService.getBlogSearch(
            axiosInstance,
            (params as { q: string })?.q,
            Number((params as { page: string })?.page || '1'),
            ARTICLES_TO_SHOW,
            language,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.BLOG_AUTOCOMPLETE_SEARCH:
      return {
        queries: [queryKey, language, (params as { q: string })?.q],
        queryFn: () =>
          ContentService.searchArticlesAutocomplete(
            axiosInstance,
            language,
            (params as { q: string })?.q,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.BLOG_TAG:
      return {
        queries: [
          queryKey,
          language,
          (params as { slug: string })?.slug,
          (params as { page: string })?.page || '1',
        ],
        queryFn: () =>
          PageService.getBlogTag(
            axiosInstance,
            (params as { slug: string })?.slug,
            Number((params as { page: string })?.page || '1'),
            ARTICLES_TO_SHOW,
            language,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.REFERRAL_PROGRAM:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          UserService.getReferralProgram(axiosInstance, language) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.CERTIFICATE_DATES:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          UserService.getCertificateDates(axiosInstance, language) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.CLAIM:
      return {
        queries: [queryKey, language],
        queryFn: () => ClaimsService.getClaim(axiosInstance, language) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.CLAIM_REVIEW:
      return {
        queries: [queryKey, language],
        queryFn: () => ClaimsService.reviewClaim(axiosInstance, language) as Promise<ResponseDto>,
        shouldLogged: true,
      };

    case EQueryKey.CLAIM_HISTORY:
      return {
        queries: [queryKey, language, (params as { claimId: string })?.claimId],
        queryFn: () =>
          ClaimsService.getClaimHistory(
            axiosInstance,
            language,
            Number((params as { claimId: string })?.claimId),
          ) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.ORDER_HISTORY:
      return {
        queries: [queryKey, language, (params as { orderId: string })?.orderId],
        queryFn: () =>
          OrderService.getHistoryOrder(
            axiosInstance,
            language,
            (params as { orderId: string })?.orderId,
          ) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.COUNTRIES:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          CountriesService.getCountries(axiosInstance, language) as Promise<ResponseDto>,
      };
    case EQueryKey.COUNTRIES_EN:
      return {
        queries: [queryKey],
        queryFn: () =>
          CountriesService.getCountries(axiosInstance, ELanguageTags.EN_US) as Promise<ResponseDto>,
      };
    case EQueryKey.CREDIT_CARDS:
      return {
        queries: [queryKey, language, (params as { isStrong: boolean })?.isStrong.toString()],
        queryFn: () =>
          UserService.getCreditCards(axiosInstance, language, {
            isStrong: (params as { isStrong: boolean })?.isStrong,
          }) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.CURRENCY_RATES:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          CurrencyRatesService.getCurrencyRates(axiosInstance, language) as Promise<ResponseDto>,
      };
    case EQueryKey.CUSTOMER_SURVEYS:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          CustomerSurveysService.getCustomerSurveys(
            axiosInstance,
            language,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.SPOT_PRICE_CARATS: {
      const dto: SpotPriceCaratsRequest = {
        metalIso: (params as { metalIso: EMetal.GOLD | EMetal.SILVER })?.metalIso,
        currencyIso: currency,
        weightUnit: (params as { weightUnit: SpotPriceWeightUnitType })?.weightUnit,
      };

      return {
        queries: [queryKey, language, dto.metalIso, dto.currencyIso, dto.weightUnit],
        queryFn: () =>
          WidgetService.getSpotPriceCarats(axiosInstance, language, dto) as Promise<ResponseDto>,
      };
    }
    case EQueryKey.SPOT_PRICE_CURRENCIES: {
      const dto: SpotPriceCurrenciesRequest = {
        metalIso: (params as { metalIso: EMetal.GOLD | EMetal.SILVER })?.metalIso,
        weightUnit: (params as { weightUnit: SpotPriceWeightUnitType })?.weightUnit,
      };

      return {
        queries: [queryKey, language, dto.metalIso, dto.weightUnit],
        queryFn: () =>
          WidgetService.getSpotPriceCurrencies(
            axiosInstance,
            language,
            dto,
          ) as Promise<ResponseDto>,
      };
    }
    case EQueryKey.SPOT_PRICE_WEIGHTS: {
      const dto: SpotPriceWeightsRequest = {
        metalIso: (params as { metalIso: EMetal.GOLD | EMetal.SILVER })?.metalIso,
        currencyIso: currency,
      };

      return {
        queries: [queryKey, language, dto.metalIso, dto.currencyIso],
        queryFn: () =>
          WidgetService.getSpotPriceWeights(axiosInstance, language, dto) as Promise<ResponseDto>,
      };
    }
    case EQueryKey.DELIVERY_COUNTRIES:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          DeliveryCountriesService.getDeliveryCountries(
            axiosInstance,
            language,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.DEPOSIT_DETAILS:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          UserService.getDepositDetails(axiosInstance, language) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.DISCOUNT:
      return {
        queries: [queryKey, language],
        queryFn: () => DiscountService.getDiscount(axiosInstance, language) as Promise<ResponseDto>,
      };
    case EQueryKey.FAQ_CATEGORIES:
      return {
        queries: [queryKey, language, (params as { category: EFAQCategory })?.category],
        queryFn: () => FAQService.getFAQCategories(axiosInstance, language) as Promise<ResponseDto>,
      };
    case EQueryKey.FAQ_CATEGORY:
      return {
        queries: [queryKey, language, (params as { category: string })?.category],
        queryFn: () =>
          FAQService.getFAQCategory(
            axiosInstance,
            language,
            (params as { category: EFAQCategory })?.category,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.FAQ_SEARCH_AUTOCOMPLETE:
      return {
        queries: [queryKey, language, (params as { q: string })?.q],
        queryFn: () =>
          FAQService.searchFAQAutocomplete(
            axiosInstance,
            language,
            (params as { q?: string })?.q,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.FAQ_SEARCH:
      return {
        queries: [
          queryKey,
          language,
          (params as { q?: string })?.q,
          (params as { page: string })?.page || '1',
        ],
        queryFn: () =>
          FAQService.searchFAQArticles(
            axiosInstance,
            language,
            (params as { q: string })?.q,
            Number((params as { page: string })?.page || '1'),
            FAQ_SEARCH_PER_PAGE,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.GENERAL_CONDITIONS:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          StaticPageService.staticPageBySlug(
            axiosInstance,
            language,
            'conditions',
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.IMPRESSUM:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          StaticPageService.staticPageBySlug(
            axiosInstance,
            language,
            'impressum',
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.GET_GA_PAY_HISTORY:
      return {
        queries: [queryKey, language, currency, (params as { page: string })?.page || '1'],
        queryFn: () =>
          AccountTransactionsService.getGAPayHistory(axiosInstance, language, {
            page: Number((params as { page: string })?.page || '1'),
            perPage: ACCOUNT_TRANSACTION_PER_PAGE,
          }) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.GET_STORAGE_FEES_HISTORY:
      return {
        queries: [queryKey, language, currency, (params as { page: string })?.page || '1'],
        queryFn: () =>
          AccountTransactionsService.getStorageFeeClaimsHistory(axiosInstance, language, {
            page: Number((params as { page: string })?.page || '1'),
            perPage: ACCOUNT_TRANSACTION_PER_PAGE,
          }) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.GIFT_SHOW:
      return {
        queries: [queryKey, language, (params as { reference: string })?.reference],
        queryFn: () =>
          GiftService.getGiftInformation(
            axiosInstance,
            language,
            (params as { reference: string })?.reference,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.GIFT_USER_SHOW:
      return {
        queries: [queryKey, language, (params as { reference: string })?.reference],
        queryFn: () =>
          GiftsService.getGiftUserInformation(
            axiosInstance,
            language,
            (params as { reference: string })?.reference,
          ) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.GIFTS_RELATIONSHIPS:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          GiftsService.getGiftsRelationships(axiosInstance, language) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.COMPONENT_ARTICLES:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          HomepageArticlesService.getHomepageArticles(
            axiosInstance,
            language,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.WIDGET_BLOG_ARTICLES:
      return {
        queries: [queryKey, language, (params as { [ESanityField.SLUG]: string })?.slug],
        queryFn: () =>
          CollectionsService.getBlogArticles(
            axiosInstance,
            language,
            (params as { [ESanityField.SLUG]: string })?.slug,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.FORECASTED_DELIVERY:
      return {
        queries: [
          queryKey,
          currency,
          String((params as ForecastedDeliveryRequestEntity)?.newProduct.id),
          String((params as ForecastedDeliveryRequestEntity)?.newProduct.quantity),
          String((params as ForecastedDeliveryRequestEntity)?.countryIso),
        ],
        queryFn: () =>
          ForecastedDeliveryService.getForecastedDelivery(
            axiosInstance,
            language,
            params as ForecastedDeliveryRequestEntity,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.COMPONENT_PRODUCTS:
      return {
        queries: [
          queryKey,
          language,
          currency,
          (params as { metalIso: EMetal.GOLD | EMetal.SILVER })?.metalIso,
        ],
        queryFn: () => {
          return HomepageProductsService.getHomepageProducts(axiosInstance, language, {
            metalIso: (params as { metalIso: EMetal.GOLD | EMetal.SILVER })?.metalIso,
          }) as Promise<ResponseDto>;
        },
      };
    case EQueryKey.SPOT_PRICE_HISTORY: {
      const dto: SpotPriceHistoryRequest = {
        metalIso: (params as { metalIso: EMetal.GOLD | EMetal.SILVER })?.metalIso,
        currencyIso: currency,
      };

      return {
        queries: [queryKey, language, dto.metalIso as string, dto.currencyIso as string],
        queryFn: () =>
          WidgetService.getSpotPriceHistory(axiosInstance, language, dto) as Promise<ResponseDto>,
      };
    }
    case EQueryKey.LIVE_CHARTS: {
      const paramsChart: LiveChartsRequest = {
        metal: (params as { metal: EMetal })?.metal,
        weightUnit: (params as { weightUnit: EUnit.OUNCE | EUnit.GRAM | EUnit.KILOGRAM })
          ?.weightUnit,
        period: (params as { period: ELiveChartsPeriod })?.period,
        currency: (params as { currency: ECurrency })?.currency,
      };
      return {
        queries: [
          queryKey,
          language,
          (params as { metal: EMetal })?.metal,
          (params as { weightUnit: WeightUnitType })?.weightUnit,
          (params as { period: ELiveChartsPeriod })?.period,
          (params as { currency: ECurrency })?.currency,
        ],
        queryFn: () =>
          LiveChartsService.getLiveCharts(
            axiosInstance,
            language,
            paramsChart,
          ) as Promise<ResponseDto>,
      };
    }
    case EQueryKey.INVESTMENT_CHARTS: {
      const paramsChart: InvestmentChartsRequest = {
        metalIso: (params as { metalIso: EMetal })?.metalIso,
        currencyIso: (params as { currencyIso: ECurrency })?.currencyIso,
        initialInvestment: (params as { initialInvestment: Price })?.initialInvestment,
        monthlyInvestment: (params as { monthlyInvestment: Price })?.monthlyInvestment,
        pastDataYearCount: (params as { pastDataYearCount: number })?.pastDataYearCount,
        forecastYearCount: (params as { forecastYearCount: number })?.forecastYearCount,
      };
      return {
        queries: [
          queryKey,
          language,
          (params as { metalIso: EMetal })?.metalIso,
          (params as { currencyIso: ECurrency })?.currencyIso,
          String((params as { initialInvestment: Price })?.initialInvestment),
          String((params as { monthlyInvestment: Price })?.monthlyInvestment),
          String((params as { pastDataYearCount: number })?.pastDataYearCount),
          String((params as { forecastYearCount: number })?.forecastYearCount),
        ],
        queryFn: () =>
          LiveChartsService.getInvestmentCharts(
            axiosInstance,
            language,
            paramsChart,
          ) as Promise<ResponseDto>,
      };
    }
    case EQueryKey.MARKET_ALERTS:
      return {
        queries: [queryKey, language, currency],
        queryFn: () =>
          MarketAlertService.getAlerts(axiosInstance, language) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.MOST_PURCHASED_PRODUCTS:
      return {
        queries: [queryKey, language, currency],
        queryFn: () =>
          isLogged
            ? (MostPurchasedProductsService.getMostPurchasedProducts(axiosInstance, {
                language,
              }) as Promise<ResponseDto>)
            : (MostPurchasedProductsService.getMostPurchasedProductsVisitor(axiosInstance, {
                language,
              }) as Promise<ResponseDto>),
      };
    case EQueryKey.NEWSLETTER_SUBSCRIPTIONS:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          UserService.getNewsletterSubscriptions(axiosInstance, language) as Promise<ResponseDto>,
      };
    case EQueryKey.NEWSLETTER_SUBSCRIPTIONS_WITH_TOKEN:
      return {
        queries: [queryKey, language, (params as { token: string })?.token],
        queryFn: () =>
          UserService.getNewsletterSubscriptionsWithToken(
            axiosInstance,
            language,
            (params as { token: string })?.token,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.ONFIDO_SDK:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          OnfidoSDKService.getOnfidoSDK(axiosInstance, language) as Promise<ResponseDto>,
      };
    case EQueryKey.PRECIOUS_METAL_GUIDE_ARTICLE: {
      const slug =
        (params as { subSubSlug?: string })?.subSubSlug ||
        (params as { subSlug?: string })?.subSlug ||
        (params as { slug?: string })?.slug;

      return {
        queries: [queryKey, language, slug],
        queryFn: () =>
          PageService.getPreciousMetalArticleBySlug(
            axiosInstance,
            slug,
            language,
          ) as Promise<ResponseDto>,
      };
    }
    case EQueryKey.PRECIOUS_METAL_GUIDE_MAIN_ARTICLE:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          PageService.getPreciousMetalGuideMainArticle(
            axiosInstance,
            language,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.PRIVACY_POLICY:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          StaticPageService.staticPageBySlug(
            axiosInstance,
            language,
            'privacy',
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.SHOP: {
      return {
        queries: [queryKey, language, currency],
        queryFn: () =>
          PageService.getShopPage(axiosInstance, { locale: language }) as Promise<ResponseDto>,
      };
    }
    case EQueryKey.PRODUCT_ALERTS:
      return {
        queries: [queryKey, language, currency],
        queryFn: () =>
          ProductAlertService.getAlerts(axiosInstance, language) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.PRODUCT_SHOW:
      return {
        queries: [queryKey, language, currency, (params as { id: string })?.id],
        queryFn: () =>
          PageService.getProductPage(
            axiosInstance,
            params as { id: string },
            language,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.PRODUCT:
      return {
        queries: [queryKey, language, currency, (params as { id: string })?.id],
        queryFn: () =>
          ProductService.getProductById(
            axiosInstance,
            language,
            (params as { id: string }).id,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.SILVER_LANDING:
      return {
        queries: [queryKey, language, currency],
        queryFn: () =>
          PageService.getSilverLandingPage(axiosInstance, language) as Promise<ResponseDto>,
      };
    case EQueryKey.PURCHASING_LIMIT:
      return {
        queries: [queryKey, language, currency],
        queryFn: () =>
          UserService.getPurchasingLimit(axiosInstance, language) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.REFERRALS:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          StaticPageService.staticPageBySlugWithAuth(
            axiosInstance,
            language,
            'referral-program-terms',
          ) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.SEARCH_ALL:
      return {
        queries: [queryKey, language, (params as { q?: string })?.q],
        queryFn: () =>
          SearchService.searchAll(
            axiosInstance,
            language,
            (params as { q?: string })?.q,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.SEARCH_PRODUCTS:
      return {
        queries: [
          queryKey,
          language,
          currency,
          (params as { q?: string })?.q,
          (params as { page: string })?.page || '1',
        ],
        queryFn: () =>
          ProductService.searchProducts(
            axiosInstance,
            language,
            (params as { q?: string })?.q,
            Number((params as { page: string })?.page || '1'),
            SEARCH_PRODUCT_PER_PAGE,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.SHOP_SUBCATEGORY:
      return {
        queries: [queryKey, language, currency, (params as { id?: string })?.id],
        queryFn: () =>
          PageService.getSubCategoriesShopPage(axiosInstance, {
            locale: language,
            id: (params as { id?: string })?.id,
          }) as Promise<ResponseDto>,
      };
    case EQueryKey.SPOT_PRICES:
      return {
        queries: [queryKey, language, currency],
        queryFn: () => PriceService.getSpotPrices(axiosInstance, language) as Promise<ResponseDto>,
      };
    case EQueryKey.KYC_QUESTION_LIST:
      return {
        queries: [queryKey, language],
        queryFn: () => KycService.getQuestionList(axiosInstance, language) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.USER_KYC_STEPS:
      return {
        queries: [queryKey],
        queryFn: () => KycService.getUserSteps(axiosInstance, language) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.STOCK_ALERTS:
      return {
        queries: [queryKey, language],
        queryFn: () => StockAlertService.getAlerts(axiosInstance, language) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.STORAGE_FEES:
      return {
        queries: [queryKey, language, (params as { currency?: ECurrency })?.currency || currency],
        queryFn: () => UserService.getStorageFees(axiosInstance, language) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.STORAGE_FEES_INVOICES:
      return {
        queries: [queryKey],
        queryFn: () =>
          UserService.getStorageFeesInvoices(axiosInstance, language, {
            page: Number((params as { page: string })?.page || '1'),
            perPage: INVOICES_PER_PAGE_STEP,
          }) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.TEAM_MEMBERS:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          TeamMembersService.getTeamMembers(axiosInstance, language) as Promise<ResponseDto>,
      };
    case EQueryKey.TOTP:
      return {
        queries: [queryKey, language],
        queryFn: () => UserService.postTotp(axiosInstance, language) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.TUTORIAL_VIDEO:
      return {
        queries: [queryKey, language, (params as { slug: string })?.slug],
        queryFn: () =>
          TutorialVideosService.getTutorialVideoBySlug(
            axiosInstance,
            language,
            (params as { slug: string })?.slug,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.TUTORIAL_VIDEOS:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          TutorialVideosService.getTutorialVideos(axiosInstance, language) as Promise<ResponseDto>,
      };
    case EQueryKey.TRUSTPILOT:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          TrustpilotService.getTotalUsers(axiosInstance, language) as Promise<ResponseDto>,
      };
    case EQueryKey.WAITING_GIFTS:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          GiftsService.getWaitingGifts(axiosInstance, language) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.WALLET:
      return {
        queries: [queryKey, language],
        queryFn: () => WalletService.getWallet(axiosInstance, language) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.WALLET_AUTO_SAVINGS_PRODUCT:
      return {
        queries: [
          queryKey,
          language,
          (params as { currency: ECurrency })?.currency || currency,
          (params as { id: number })?.id.toString(),
        ],
        queryFn: () =>
          UserService.getAutoSavingsProductById(
            axiosInstance,
            language,
            (params as { id: number })?.id,
          ) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.WALLET_AUTO_SAVINGS_PRODUCTS:
      return {
        queries: [queryKey, language, (params as { currency: ECurrency })?.currency || currency],
        queryFn: () =>
          UserService.getAutoSavingsProducts(axiosInstance, language) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.WALLET_CLAIMS:
      return {
        queries: [
          queryKey,
          language,
          (params as { currency: ECurrency })?.currency || currency,
          (params as { page: string })?.page || '1',
        ],
        queryFn: () =>
          HistoryService.getHistoryClaims(axiosInstance, language, {
            page: Number((params as { page: string })?.page || '1'),
            perPage: HISTORY_CLAIM_PER_PAGE,
          }) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.WALLET_CLAIM_SHOW:
      return {
        queries: [
          queryKey,
          language,
          (params as { currency: ECurrency })?.currency || currency,
          (params as { id: number })?.id.toString(),
        ],
        queryFn: () =>
          HistoryService.getClaimById(
            axiosInstance,
            language,
            (params as { id: number })?.id,
          ) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.WALLET_GIFTS:
      return {
        queries: [
          queryKey,
          language,
          (params as { currency: ECurrency })?.currency || currency,
          (params as { page: string })?.page || '1',
        ],
        queryFn: () =>
          HistoryService.getHistoryGifts(axiosInstance, language, {
            page: Number((params as { page: string })?.page || '1'),
            perPage: HISTORY_GIFT_PER_PAGE,
          }) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.WALLET_GIFT_SHOW:
      return {
        queries: [
          queryKey,
          language,
          (params as { currency: ECurrency })?.currency || currency,
          (params as { referenceNumber?: string })?.referenceNumber,
        ],
        queryFn: () =>
          HistoryService.getGiftByReferenceNumber(
            axiosInstance,
            language,
            (params as { referenceNumber?: string })?.referenceNumber,
          ) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.WALLET_ORDERS:
      return {
        queries: [
          queryKey,
          language,
          (params as { currency: ECurrency })?.currency || currency,
          (params as { page: string })?.page || '1',
        ],
        queryFn: () =>
          HistoryService.getHistoryOrders(axiosInstance, language, {
            page: Number((params as { page: string })?.page || '1'),
            perPage: HISTORY_ORDER_PER_PAGE,
          }) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.WALLET_ORDER_SHOW:
      return {
        queries: [
          queryKey,
          language,
          (params as { currency: ECurrency })?.currency || currency,
          (params as { id?: number })?.id.toString(),
        ],
        queryFn: () =>
          HistoryService.getOrderById(
            axiosInstance,
            language,
            (params as { id?: number })?.id,
          ) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.WALLET_SALES:
      return {
        queries: [queryKey, language],
        queryFn: () =>
          WalletService.getWalletSales(axiosInstance, language) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.WISHLIST:
      return {
        queries: [queryKey, language, currency],
        queryFn: () => WishlistService.getWishlist(axiosInstance, language) as Promise<ResponseDto>,
        shouldLogged: true,
      };
    case EQueryKey.PAGE_AUTO_SAVINGS_PRODUCTS:
      return {
        queries: [queryKey, language, currency],
        queryFn: () =>
          PageService.getAutoSavingsPage(axiosInstance, language) as Promise<ResponseDto>,
      };
    case EQueryKey.COMPONENT_COLLECTIONS_BANNER: {
      const bannerSlug = (params as CollectionsBannersRequest)?.bannerSlug;

      return {
        queries: [queryKey, language, currency, bannerSlug],
        queryFn: () =>
          CollectionsService.getBanners(
            axiosInstance,
            language,
            bannerSlug,
          ) as Promise<ResponseDto>,
      };
    }
    case EQueryKey.COMPONENT_COLLECTIONS_PRODUCT: {
      const slugs =
        (
          params as {
            slugs: string[];
          }
        )?.slugs || [];
      return {
        queries: [queryKey, language, currency, ...slugs],
        queryFn: () =>
          CollectionsService.getProductGroups(
            axiosInstance,
            language,
            slugs,
          ) as Promise<ResponseDto>,
      };
    }
    case EQueryKey.PAGE_BLOG_HOME:
      return {
        queries: [queryKey, language, (params as { page: string })?.page || '1'],
        queryFn: () =>
          PageService.getBlogHomePage(
            axiosInstance,
            language,
            Number((params as { page: string })?.page || '1'),
            ARTICLES_TO_SHOW,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.PAGE_STORAGE_SOLUTION:
      return {
        queries: [queryKey, language, EFAQCategory.STORAGE_SOLUTION],
        queryFn: () =>
          PageService.getFaqQuestions(
            axiosInstance,
            EFAQCategory.STORAGE_SOLUTION,
            language,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.PAGE_CONTACT_FAQ:
      return {
        queries: [queryKey, language, EFAQCategory.CONTACT],
        queryFn: () =>
          PageService.getFaqQuestions(
            axiosInstance,
            EFAQCategory.CONTACT,
            language,
          ) as Promise<ResponseDto>,
      };
    case EQueryKey.WIDGET_PAGE_RATING:
      return {
        queries: [queryKey, language, (params as { pageId: string })?.pageId],
        queryFn: () => {
          return WidgetService.getPageRating(
            axiosInstance,
            language,
            params as PageRatingRequest,
          ) as Promise<ResponseDto>;
        },
      };
    case EQueryKey.WIDGET_PRODUCT_GROUP:
      return {
        queries: [queryKey, language, (params as { slug: string })?.slug],
        queryFn: () => {
          return CollectionsService.getProductGroup(
            axiosInstance,
            language,
            (params as { slug: string })?.slug,
          ) as Promise<ResponseDto>;
        },
      };
    default:
      return null;
  }
}

export function getSanityPageQueryKeys(keyRoute: ERouting): EQueryKey[] {
  switch (keyRoute) {
    case ERouting.SHOP_SUBCATEGORY:
      return [EQueryKey.SHOP_SUBCATEGORY];
    case ERouting.HOME_BUY:
      return [EQueryKey.SHOP];
    case ERouting.BUY:
      return [EQueryKey.SHOP];
    case ERouting.PRODUCT_SHOW:
      return [EQueryKey.PRODUCT_SHOW];
    default:
      return [];
  }
}

export function getPageParamsQueriesKeys(keyRoute: ERouting, slug?: string): EQueryKey {
  switch (keyRoute) {
    case ERouting.BLOG_ARTICLE:
      return EQueryKey.BLOG_ARTICLE;
    case ERouting.FAQ_SHOW:
      return EQueryKey.FAQ_ARTICLE;
    case ERouting.BLOG_CATEGORY:
      return EQueryKey.BLOG_CATEGORY;
    case ERouting.BLOG_SEARCH:
      return EQueryKey.BLOG_SEARCH;
    case ERouting.BLOG_TAG:
      return EQueryKey.BLOG_TAG;
    case ERouting.FAQ:
      return EQueryKey.FAQ_CATEGORIES;
    case ERouting.FAQ_SEARCH:
      return EQueryKey.FAQ_SEARCH;
    case ERouting.IMPRESSUM:
      return EQueryKey.IMPRESSUM;
    case ERouting.DETAILS_GIFT:
      return EQueryKey.GIFT_SHOW;
    case ERouting.GIFT_PENDING:
      return EQueryKey.GIFT_SHOW;
    case ERouting.CHARTS:
      return EQueryKey.LIVE_CHARTS;
    case ERouting.PRECIOUS_METAL_GUIDE:
      return EQueryKey.PRECIOUS_METAL_GUIDE_MAIN_ARTICLE;
    case ERouting.PRECIOUS_METAL_GUIDE_LEVEL1:
      return EQueryKey.PRECIOUS_METAL_GUIDE_ARTICLE;
    case ERouting.PRECIOUS_METAL_GUIDE_LEVEL2:
      return EQueryKey.PRECIOUS_METAL_GUIDE_ARTICLE;
    case ERouting.PRECIOUS_METAL_GUIDE_LEVEL3:
      return EQueryKey.PRECIOUS_METAL_GUIDE_ARTICLE;
    case ERouting.CART:
      return EQueryKey.CART;
    case ERouting.PRODUCT_SHOW:
      return EQueryKey.PRODUCT_SHOW;
    case ERouting.SILVER_LANDING:
      return EQueryKey.SILVER_LANDING;
    case ERouting.SEARCH:
      return EQueryKey.SEARCH_PRODUCTS;
    case ERouting.SHOP_SUBCATEGORY:
      return EQueryKey.SHOP_SUBCATEGORY;
    case ERouting.TUTORIAL_VIDEO:
      return EQueryKey.TUTORIAL_VIDEO;
    case ERouting.TUTORIAL_VIDEOS:
      return EQueryKey.TUTORIAL_VIDEOS;
    case ERouting.AUTO_SAVINGS:
      return EQueryKey.PAGE_AUTO_SAVINGS_PRODUCTS;
    case ERouting.BLOG_HOME:
      return EQueryKey.PAGE_BLOG_HOME;
    case ERouting.ABOUT_US:
      return EQueryKey.TEAM_MEMBERS;
    case ERouting.STORAGE_SOLUTION:
      return EQueryKey.PAGE_STORAGE_SOLUTION;
    case ERouting.CONTACT:
      return EQueryKey.PAGE_CONTACT_FAQ;
    case ERouting.PURCHASING_LIMIT:
      return EQueryKey.PURCHASING_LIMIT;
  }

  if (keyRoute === ERouting.PAGES) {
    if (GENERAL_CONDITIONS_SLUGS.includes(slug)) {
      return EQueryKey.GENERAL_CONDITIONS;
    }
    if (PRIVACY_POLICY_SLUGS.includes(slug)) {
      return EQueryKey.PRIVACY_POLICY;
    }
  }

  return null;
}

export function getSanityWidgetQueryKeys(component: ESanityWidget): EQueryKey[] {
  switch (component) {
    case ESanityWidget.PRODUCTS:
      return [EQueryKey.COMPONENT_PRODUCTS];
    case ESanityWidget.CHARTS:
      return [EQueryKey.LIVE_CHARTS];
    case ESanityWidget.BLOG_ARTICLE:
      return [EQueryKey.WIDGET_BLOG_ARTICLES];
    case ESanityWidget.ARTICLES:
      return [EQueryKey.COMPONENT_ARTICLES];
    case ESanityWidget.FAQ:
      return [EQueryKey.FAQ_CATEGORY];
    case ESanityWidget.CAROUSEL_PRODUCT:
      return [EQueryKey.COMPONENT_COLLECTIONS_PRODUCT];
    case ESanityWidget.CAROUSEL_BANNER:
      return [EQueryKey.COMPONENT_COLLECTIONS_BANNER];
    case ESanityWidget.DISCOUNT_BANNER:
      return [EQueryKey.DISCOUNT];
    case ESanityWidget.PAGE_RATING:
      return [EQueryKey.WIDGET_PAGE_RATING];
    case ESanityWidget.TABLE_SPOT_PRICE_HISTORY:
      return [EQueryKey.SPOT_PRICE_HISTORY];
    case ESanityWidget.CAROUSEL_TRUSTPILOT:
      return [EQueryKey.TRUSTPILOT];
    case ESanityWidget.TABLE_SPOT_PRICE_DAILY_STAT:
      return [
        EQueryKey.SPOT_PRICE_CARATS,
        EQueryKey.SPOT_PRICE_CURRENCIES,
        EQueryKey.SPOT_PRICE_WEIGHTS,
      ];

    default:
      return [];
  }
}

export const getPageParamsQueries = (
  page: ERouting,
  language: ELanguageTags,
  query: ParsedUrlQuery,
) => {
  switch (page) {
    case ERouting.HOME:
      return [
        { queryKey: EQueryKey.COMPONENT_PRODUCTS, params: { metalIso: EMetal.GOLD } },
        { queryKey: EQueryKey.COMPONENT_ARTICLES, params: {} },
        { queryKey: EQueryKey.COMPONENT_COLLECTIONS_BANNER, params: {} },
        { queryKey: EQueryKey.COMPONENT_COLLECTIONS_PRODUCT, params: {} },
        { queryKey: EQueryKey.DISCOUNT, params: {} },
        { queryKey: EQueryKey.TRUSTPILOT, params: { language } },
        { queryKey: EQueryKey.FAQ_CATEGORY, params: { category: EFAQCategory.HOME_PAGE } },
      ];
    case ERouting.CHARTS:
      return [
        { queryKey: EQueryKey.LIVE_CHARTS, params: { metalIso: EMetal.GOLD } },
        {
          queryKey: EQueryKey.SPOT_PRICE_HISTORY,
          params: { metalIso: EMetal.GOLD },
        },
      ];
    case ERouting.PAGES:
      if (typeof query.slug === 'string' && GENERAL_CONDITIONS_SLUGS.includes(query.slug)) {
        return [{ queryKey: EQueryKey.GENERAL_CONDITIONS, params: {} }];
      }
      if (typeof query.slug === 'string' && PRIVACY_POLICY_SLUGS.includes(query.slug)) {
        return [{ queryKey: EQueryKey.PRIVACY_POLICY, params: {} }];
      }
      return [];
    default:
      return [];
  }
};
