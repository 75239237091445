import { useEventMixpanel } from '@mixpanel';
import NextLink from 'next/link';
import styled, { CSSObject } from 'styled-components';

import {
  Box,
  ButtonIcon,
  ContainerCarouselViews,
  ImageApi,
  SkeletonTypography,
  SlickSlider,
} from '@core/component';
import { IconProps } from '@core/component/interface';
import { useContextMediaQuery } from '@core/context';
import { useQueryApi } from '@core/hook';
import { EColor, EIcon, EPosition, ESize } from '@core/type';
import { CollectionsBannersRequest, CollectionsBannersResponse, EQueryKey } from '@core/type/api';

import { useCarouselBanners } from './use-carousel-banners';

export const CarouselBanners = ({ bannerSlug }: { bannerSlug: string }) => {
  const { isPhone } = useContextMediaQuery();

  const {
    data: collection,
    isLoading,
    isError,
  } = useQueryApi<CollectionsBannersResponse, CollectionsBannersRequest>(
    EQueryKey.COMPONENT_COLLECTIONS_BANNER,
    { bannerSlug },
  );

  const {
    bannersProps,
    containerRef,
    setSlide,
    currentStep,
    totalSteps,
    handleNext,
    handlePrevious,
    startAutoplay,
    stopAutoplay,
    ...restStepsProps
  } = useCarouselBanners(collection);
  const { trackCarouselBannersEvent } = useEventMixpanel();

  if (isError) {
    return null;
  }

  const sliderHeight = isPhone ? '350px' : '300px';

  if (isLoading) {
    return (
      <Box marginBottom={'48px'} height={sliderHeight}>
        <SkeletonTypography lineHeight={'68px'} marginBottom={'8px'} />
        <SkeletonTypography lineHeight={'68px'} />
      </Box>
    );
  }

  const banners = bannersProps.items;

  return (
    <Box onMouseEnter={stopAutoplay} onMouseLeave={startAutoplay}>
      <Box position={'relative'} ref={containerRef}>
        <Box
          overflowX={'hidden'}
          position={'relative'}
          height={sliderHeight}
          borderRadius={isPhone ? '10px' : '32px'}
        >
          <Box position={'absolute'} display={'flex'} left={'0'} right={'0'} overflowX={'visible'}>
            <ContainerCarouselViews gap={isPhone ? '16px' : '30px'} {...restStepsProps}>
              {banners.map(({ id, desktopImage, mobileImage, link }, index) => {
                const imageProps = isPhone ? mobileImage : desktopImage;

                return (
                  <NextLink
                    locale={false}
                    key={id}
                    href={link}
                    style={{
                      display: 'block',
                      position: 'relative',
                      width: '100%',
                      height: sliderHeight,
                      flexShrink: '0',
                    }}
                    onClick={() => trackCarouselBannersEvent({ id })}
                  >
                    <ImageApi
                      {...imageProps}
                      title={null}
                      style={{
                        objectPosition: 'center',
                        objectFit: 'cover',
                      }}
                      quality={100}
                      priority={index === 0}
                    />
                  </NextLink>
                );
              })}
            </ContainerCarouselViews>
          </Box>
        </Box>

        {totalSteps > 1 && !isPhone && (
          <>
            <ButtonArrow orientation={EPosition.LEFT} onClick={handlePrevious} />
            <ButtonArrow orientation={EPosition.RIGHT} onClick={handleNext} />
          </>
        )}
      </Box>

      {totalSteps > 1 && (
        <SlickSlider
          totalSteps={totalSteps}
          currentStep={currentStep}
          onPrevious={handlePrevious}
          onNext={handleNext}
          onClick={setSlide}
          withCarousel
          marginTop={'24px'}
        />
      )}
    </Box>
  );
};

const StyledButtonWrapper = styled(Box)(
  ({ orientation }: Pick<IconProps, 'orientation'>) =>
    ({
      position: 'absolute',
      top: 'calc(50% - 20px)',
      left: orientation === EPosition.LEFT ? '-20px' : 'auto',
      right: orientation === EPosition.RIGHT ? '-20px' : 'auto',
      height: '40px',
      width: '40px',
    }) as CSSObject,
);

const ButtonArrow = ({ orientation, onClick }: Pick<IconProps, 'orientation' | 'onClick'>) => {
  return (
    <StyledButtonWrapper orientation={orientation}>
      <ButtonIcon
        position={'relative'}
        onClick={onClick}
        padding={'0'}
        backgroundColor={{ semanticType: EColor.SECONDARY, variant: EColor.LIGHT }}
        display={'flex'}
        borderRadius={'50%'}
        alignItems={'center'}
        justifyContent={'center'}
        iconProps={{ type: EIcon.ANGLE, strokeWidth: 1.8, orientation, size: ESize.MD }}
        height={'40px'}
        width={'40px'}
      />
    </StyledButtonWrapper>
  );
};
